.contact-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(./babies.jpg);
}

#contact-form {
    width: 50%;
}

.height-30 {
    height: 30vh;
}

#contact-form .form-control:focus,
#contact-form .form-select:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25);
}

@media only screen and (max-width: 992px) {
    #contact-form {
        width: 75%;
    }
}

@media only screen and (max-width: 576px) {
    #contact-form {
        width: 100%;
    }
}

.contact-page .card-img-overlay {
    background-color: rgba(5, 10, 35, 0.7);
}

.phonesColor {
    font-size: 1.0rem;
    color: #2b2b2b;
    font-weight: bold;
  }