.applyNow-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(./pp2\ 1.jpg);
}

#applyNow-form {
    width: 50%;
}

.height-30 {
    height: 30vh;
}

.titles {
    text-decoration-line: underline;
}

#applyNow-form .form-control:focus,
#applyNow-form .form-select:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, .25);
}

@media only screen and (max-width: 992px) {
    #applyNow-form {
        width: 75%;
    }
}

@media only screen and (max-width: 576px) {
    #applyNow-form {
        width: 100%;
    }
}