.App {
  text-align: center;
  min-height: 100vh;
}

h1, h2, h3, p, a {
  font-family: 'Montserrat', sans-serif;
}

.navbar .nav-link {
  font-size: 14px;
  color: #ffffff;
}

.navbar .navbar-nav .nav-link:hover {
  color: #dc3545;
  transition: 500ms ease-in-out;
}

/* Remove background from the toggler button */
.navbar-toggler {
  background: none;
  border: none;
  opacity: 0.7;
}

nav .navbar-toggler:focus {
  box-shadow: none;
}

.footer-social-icons li:hover svg {
  fill: #adadad;
  transition: 500ms ease-in-out;
  scale: 1.2;
}

.footer-navigation li:hover {
  color: #c5c6c7;
  transition: 500ms ease-in-out;
}


header h1 {
  font-size: 4rem;
}

.scalar:hover {
  scale: 1.05;
  transition: 400ms ease-in-out;
}

@media only screen and (max-width: 992px) {
  #basic-navbar-nav {
    background-color: #bdbdbd;
    padding: 10px 20px;
    border-radius: 0.375rem;
    transition: 300ms ease-in-out;
  }

  .navbar .nav-link {
    color: #212529;
  }
}

@media only screen and (max-width: 768px) {
  header h1 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 576px) {
  header h1 {
    font-size: 2rem;
  }
}

.social-icons-line {
  border-top: 4px solid #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn:hover {
  cursor: pointer;
  scale: 1.1;
  transition: 500ms ease-in-out;
}

.phones {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: bold;
}

#applyNow {
  background-color: #dc3545;
  color: #ffffff;
  font-weight: bold;
  border-radius: 0.375rem;
}

#contactUs {
  background-color: #555555;
  color: #ffffff;
  font-weight: bold;
  border-radius: 0.375rem;
}

#applyNow:hover {
  scale: 1.05;
  transition: 500ms ease-in-out;
}

#contactUs:hover {
  scale: 1.05;
  transition: 500ms ease-in-out;
}
.footlink:hover {
  color: #dc3545;
  scale: 1.05;
  transition: 500ms ease-in-out;
}