.fee-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(./home-page-header.jpg);
  }
  
  .height-30 {
    height: 30vh;
  }
  
  .text-justify {
    text-align: justify;
  }
  
  .fee-page .card-img-overlay {
    background-color: rgba(5, 10, 35, 0.7);
  }
  
  .fee-page .card:hover {
    cursor: pointer;
    scale: 1.05;
    transition: 300ms ease-in-out;
  }
  
  @media (max-width: 767px) {
    .text-justify {
      text-align: left;
    }
  }
  
  @media (min-width: 768px) {
    .flex-row-reverse {
      flex-direction: row-reverse;
    }
  }
  
  table {
    font-family: Arial, sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }
  
  thead tr {
    color: #ffffff;
  }
  
  th, td {
    padding: 12px 15px;
    border: 1px solid #808080;
    text-align: center;
  }
  
  tbody tr {
    border-bottom: 1px solid #525252;
  }
  
  .fee-table .fee-card:hover {
    scale: 1.00;
    cursor: pointer;
  }
  