.classes-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(./start-courses-img.jpg);
}

.height-30 {
    height: 30vh;
}
  
.text-justify {
    text-align: justify;
}
  
@media (max-width: 767px) {
    .text-justify {
      text-align: left;
    }
}
  
@media (min-width: 768px) {
    .flex-row-reverse {
      flex-direction: row-reverse;
    }
}

.our-classes-data .our-classes-card:hover {
    scale: 1.0;
}