.home-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(./home-page-header.jpg);
}

header {
    background-size: cover;
    background-position: center;
}

.blog-section {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(./blog-section-img.jpg);
    background-size: cover;
    background-position: center;
}

.height-92 {
    height: 92.1vh;
}