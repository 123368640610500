.courses-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75), rgba(5, 10, 35, 0.75)), url(./group.jpg);
}

.height-30 {
    height: 30vh;
}

.courses-page .card-img-overlay {
    background-color: rgba(5, 10, 35, 0.7);
}

.courses-page .card:hover {
    cursor: pointer;
    scale: 1.05;
    transition: 300ms ease-in-out;
}

.programme-data .programme-card:hover {
    scale: 1.00;
}
