.faq-section p,
.accordion {
    width: 75%;
}

.accordion .accordion-button:focus,
.accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.accordion .accordion-button:not(.collapsed) {
    background-color: #ee7a83;
    color: #ffffff;
}

.accordion .accordion-button:not(.collapsed)::after {
    filter: invert();
}

@media only screen and (max-width: 992px) {
    .faq-section p,
    .accordion {
        width: 100%;
    }
}